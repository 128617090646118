<template>
    <div class="pdf-viewer-container" @contextmenu.prevent="handleRightClick">
      <div
        class="pdf-content"
        ref="pdfContent"
        :class="{ fullscreen: isFullscreen }"
        @contextmenu.prevent="handleRightClick"
      >
        <!-- Loading State -->
        <div v-if="isLoading" class="loading-overlay">
          <div class="loading-spinner"></div>
          <div class="loading-text">Loading PDF...</div>
        </div>
  
        <!-- PDF Viewer -->
        <div class="pdf-frame-container">
          <iframe
            v-show="!isLoading"
            :src="currentPdfUrl"
            class="pdf-frame"
            ref="pdfFrame"
            @load="handlePdfLoad"
            @error="handlePdfError"
          ></iframe>
          <!-- Overlay to block interactions but allow scrolling -->
          <div class="iframe-overlay" @contextmenu.prevent="handleRightClick"></div>
        </div>
  
        <!-- Navigation Controls -->
        <div class="navigation-controls" v-show="!isLoading">
          <button
            @click="previousPage"
            :disabled="currentPage <= 1"
            class="nav-button"
          >
            Previous
          </button>
          <span class="page-info">
            Page {{ currentPage }} of {{ totalPages }}
          </span>
          <button
            @click="nextPage"
            :disabled="currentPage >= totalPages"
            class="nav-button"
          >
            Next
          </button>
        </div>
  
        <!-- Zoom Controls -->
        <div class="zoom-controls" v-show="!isLoading">
          <button
            @click="zoomOut"
            class="zoom-button"
            :disabled="zoomLevel <= 0.5"
          >
            -
          </button>
          <span class="zoom-info">{{ Math.round(zoomLevel * 100) }}%</span>
          <button
            @click="zoomIn"
            class="zoom-button"
            :disabled="zoomLevel >= 2"
          >
            +
          </button>
        </div>
  
        <!-- Error Message -->
        <div v-if="error" class="error-message">
          {{ error }}
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed, watch } from "vue";
  
  // Props
  const props = defineProps({
    pdfUrl: {
      type: String,
      required: true,
    },
  });
  
  // Refs
  const pdfFrame = ref(null);
  const isFullscreen = ref(false);
  const currentPage = ref(1);
  const zoomLevel = ref(1);
  const isLoading = ref(true);
  const error = ref(null);
  const totalPages = ref(10); // Adjust if dynamic page count is required
  
  // Computed
  const currentPdfUrl = computed(() => {
    const baseUrl = props.pdfUrl;
    const pageParam = `#page=${currentPage.value}`;
    const zoomParam = `&zoom=${zoomLevel.value * 100}`;
    const toolbarParam = "&toolbar=0&navpanes=0";
    return `${baseUrl}${pageParam}${zoomParam}${toolbarParam}`;
  });
  
  // Methods
  const handleRightClick = (event) => {
    event.preventDefault();
    console.log("Right-click disabled!");
  };
  
  const handlePdfLoad = () => {
    isLoading.value = false;
    error.value = null;
  };
  
  const handlePdfError = () => {
    isLoading.value = false;
    error.value = "Failed to load PDF. Please try again.";
  };
  
  const nextPage = () => {
    if (currentPage.value < totalPages.value) {
      currentPage.value++;
    }
  };
  
  const previousPage = () => {
    if (currentPage.value > 1) {
      currentPage.value--;
    }
  };
  
  const zoomIn = () => {
    if (zoomLevel.value < 2) {
      zoomLevel.value = Math.round((zoomLevel.value + 0.1) * 10) / 10;
    }
  };
  
  const zoomOut = () => {
    if (zoomLevel.value > 0.5) {
      zoomLevel.value = Math.round((zoomLevel.value - 0.1) * 10) / 10;
    }
  };
  
  const refreshPdf = () => {
    if (pdfFrame.value) {
      isLoading.value = true;
      const src = currentPdfUrl.value;
      pdfFrame.value.src = "";
      setTimeout(() => {
        pdfFrame.value.src = src;
      }, 100); // Prevent caching issues
    }
  };
  
  // Watchers
  watch([currentPage, zoomLevel], () => {
    refreshPdf();
  });
  </script>
  
  <style scoped>
  .pdf-viewer-container {
    width: 100%;
    height: 100%;
    min-height: 80vh;
    position: relative;
    background: #ffffff;
    display: flex;
    flex-direction: column;
  }
  
  .pdf-content {
    flex: 1;
    position: relative;
    overflow: hidden;
    background: #ffffff;
    display: flex;
    flex-direction: column;
  }
  
  .pdf-frame-container {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
  }
  
  .pdf-frame {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .iframe-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent; /* Transparent overlay */
    z-index: 10; /* Ensure it's above the iframe */
    pointer-events: none; /* Allow scrolling and other interactions */
  }
  
  .iframe-overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto; /* Block interactions except scrolling */
    cursor: not-allowed; /* Indicate that interaction is blocked */
  }
  
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
  
  .loading-text {
    font-size: 16px;
    color: #333;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .navigation-controls,
  .zoom-controls {
    position: absolute;
    display: flex;
    gap: 10px;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    padding: 8px;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
  
  .navigation-controls {
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .zoom-controls {
    bottom: 10px;
    right: 10px;
  }
  
  .nav-button,
  .zoom-button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .nav-button:disabled,
  .zoom-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .error-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ff4444;
    color: white;
    padding: 16px 24px;
    border-radius: 8px;
    z-index: 1001;
  }
  </style>